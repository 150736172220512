body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'CircularStdBold';
  src: local('CircularStdBold'), url(./fonts/CircularStd-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'CircularStdBook';
  src: local('CircularStdBook'), url(./fonts/CircularStd-Book.otf) format('truetype');
}


@media only screen and (min-width: 1023px) {
  button {
    font-size: 19px !important;
  }
}


#registration-form {
  max-width: 600px;
}

.form-control:focus {
  border-color: #FF5B14;
  box-shadow: 0 0 0 0.2rem rgba(255, 102, 2,.25);
}

.next-button-container {
  text-align: center;
  margin-top: 8vh;
}

.next-button {
  width: 266px;
  height: 60px;
  border-radius: 30px;
  border: none;
}

.next-button p {
  margin: 0.5rem 0;
  font-size: 19px;
  letter-spacing: 0.9px;
}

.at_home-next-button {
   background-color: #FF5B14;
}

.yoga-next-button {
  background-color: #ff8a8a;
}

.running-next-button {
  background-color: #fff400;
}

.running-next-button p {
  color: #182454 !important;
}

.at_home-next-button p {
  color: #fff;
  font-weight: 700;
}

.running-next-button:hover {
  background-color: #ffff99;
  border: none;
}

.running-next-button:focus {
  background-color: #ffff99;
  border: none;
}

.running-next-button:active {
  background-color: #fff;
  border: none;
}

.at_home-next-button:hover:not([disabled]) p {
  color: #FF5B14 !important;
  border: none;
  font-weight: 700;
}

.at_home-next-button:hover:not([disabled]) {
  background-color: #fff;
  border: 1px solid #FF5B14;
}

.at_home-next-button:active:not([disabled]) {
  background-color: #FF5B14;
  border: none;
}

.yoga-next-button:hover {
  background-color: #e2a7a7;
  border: none;
}

.yoga-next-button:focus {
  background-color: #e2a7a7;
  border: none;
}

.yoga-next-button:active {
  background-color: #eee;
  border: none;
}

.yoga-next-button p {
  color: #fff;
}

.registration-disclaimer p {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.registration-disclaimer a {
  text-decoration: underline;
}

.error-message {
  color: #be2202;
  font-size: 14px;
  text-align: center;
}

.underline-text {
  color: #be2202;
  text-decoration: underline;
}

hr {
  margin: 0;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.grecaptcha-badge {
  bottom: 48px !important;
}

.core-reg-header {
  height: 100px;
}

.core-reg-header-logo {
  text-align: center;
  padding: 2vh 0;
}

.core-reg-hero-container {
  background: url("https://assets1.dailyburn.com/production/d2c/core-registration/hero-image.jpg") no-repeat center top;
  background-size: cover;
  margin: 0;
  height: 348px;
  position: relative;
}

.core-reg-hero-wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 30px;
}

.core-reg-hero-copy {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  top: 0;
}

.core-reg-headline {
  padding-left: 0;
  color: #ff5b14;
  font-size: 24px;
  font-weight: bold;
}

.core-reg-step {
  color: #2b2b2f;
  font-size: 12px;
  margin-bottom: 0;
}

.core-reg-step-info {
  color: #474e5b;
  font-size: 16px;
  margin-bottom: 0;
}

.core-reg-member-info-inputs input {
  margin-top: 32px;
}

.core-reg-step-disclaimer {
  margin: 0 0 32px 0;
  font-size: 12px;
  color: #71737b;
  font-weight: normal;
}

.selection-tabs-container {
  margin-bottom: 8px;
}

.selection-tabs-container:hover {
  cursor: pointer;
}

.credit-card-logo {
  margin: 3px 0 0 18px;
}

.venmo-logo {
  margin: 24px 0 0 18px;
}

.paypal-logo {
  margin: 18px 0 0 12px;
}

.checkout-option {
  margin-right: 8px;
  width: 100px;
  height: 60px;
  border-radius: 4px;
  border: solid 1px #cbcace;
  background-color: #f9f9f9;
}

.selected-checkout-option {
  border-color: #FF5B14;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.placeholder-0 {
  position: relative;
  margin-top: 8px;
}

.placeholder-0 label {
  margin:20px 0;
  position:relative;
  display:inline-block;
}

.placeholder-0 span {
  padding:8px;
  pointer-events: none;
  position:absolute;
  left:0;
  top:0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity:0.5;
}

.placeholder-0 input {
  padding:10px;
}

.placeholder-0 input:focus + span, input:not(:placeholder-shown) + span {
  opacity:1;
  transform: scale(0.75) translateY(-100%) translateX(-16px);
}

/* For IE Browsers*/
.placeholder-0 input:focus + span, input:not(:-ms-input-placeholder) + span {
  opacity:1;
  transform: scale(0.75) translateY(-100%) translateX(-16px);
}

.placeholder-0 p {
  font-size: 11px;
  font-weight: normal;
  margin: 0 0 0 8px;
}

.placeholder-0 label {
  margin: 0;
}

.step-two-container {
  padding: 0 8vw;
}

.step-two-container a {
  text-decoration: underline;
  text-transform: none;

}

.core-reg-mobile-perks-waves {
  background: url("https://assets1.dailyburn.com/production/d2c/core-registration/perks-waves.png") no-repeat center top;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 23.4%;
  margin: 8px 0 0 0;
  position: relative;
}

.core-reg-mobile-perks-waves h1 {
  position: absolute;
  font-size: 21px;
  bottom: 0;
  color: #fff;
  padding: 0;
  left: 50%;
  margin: 0 0 4px -40px;
}

.core-reg-mobile-perks-body {
  background-color: #ff8415;
  padding: 24px 0;
}

.core-reg-mobile-perks-body p {
  color: #fff;
  padding: 0;
  margin: 0 0 0 4vw;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  width: 92vw;
}

.core-reg-desktop-perks {
  display: none;
  position: absolute;
  right: 0;
  top: -108px;
}

.core-reg-complete-button {
  width: 298px;
}

.core-reg-edit-member-info {
  text-decoration: underline;
  margin: 0;
}

.core-reg-edit-member-info:hover {
  cursor: pointer;
}

.core-reg-disclaimer {
  font-weight: normal;
  margin-top: 16px;
}

.core-reg-step-info-2 {
  margin-bottom: 4px;
}

.core-reg-receipt-headline {
  color: #ff5b14;
  padding-right: 0;
  padding-bottom: 0;
}

#paypal-container {
  margin-top: 8px;
  margin-bottom: 16px;
}

#venmo-container {
  margin-top: 8px;
  margin-bottom: 16px;
}

#braintree-paypal-loggedin {
  margin-top: 16px;
  margin-bottom: 24px;
}

#pp-email-received {
  color: black;
}

#pp-cancel-button {
  float: right;
  text-decoration: underline;
  cursor: pointer;
}

#paypal:hover {
  cursor: pointer;
}

#venmo:hover {
  cursor: pointer;
}

#payment-info img {
  width: 34px;
  height: 22px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.core-reg-steps-container,
.container-siteseal,
.core-reg-hero-wrapper {
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 992px) {
  .core-reg-steps-container,
  .container-siteseal {
      width: 344px;
  }
}

@media screen and (min-width: 992px) {
  .core-reg-steps-container {
      min-height: 70vh;
      max-width: 674px;
  }
  .core-reg-hero-wrapper,
  .container-siteseal {
      max-width: 674px;
  }

  .core-reg-hero-copy {
      font-size: 32px;
  }

  .core-reg-mobile-perks {
      display: none;
  }

  .core-reg-desktop-perks {
      display: inline-block;
      width: 416px;
      height: 416px;
      border-radius: 50%;
      border: 2px solid #FF5B14;
      margin: 0;
      right: -170px;
  }

  .core-reg-desktop-perks-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ff5b14;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 6px;
      left: 6px;
  }

  .core-reg-desktop-perks h1 {
      font-size: 36px;
      color: #fff;
      text-align: center;
      width: 100%;
      padding: 0;
      margin-top: 36px;
  }

  .core-reg-desktop-perks p {
      color: #fff;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      width: 100%;
  }

  .core-reg-desktop-perks-body {
      width: 300px;
      display: inline-block;
  }

  .checkout-option {
      width: 100px;
      height: 60px;
      margin-right: 24px;
  }

  .core-reg-footer-wrapper {
      width: 100vw;
      height: auto;
      background-color: #f9f9f9;
  }

  .footer-container {
      padding-top: 12px;
  }

  .step-two-container {
      padding: 0 4vw;
  }
}

@media screen and (min-width: 1320px) {
  .core-reg-steps-container {
      min-height: 70vh;
      max-width: 900px;
  }
  .core-reg-hero-wrapper,
  .container-siteseal {
      max-width: 900px;
  }
  .core-reg-desktop-perks {
      right: 0;
  }
}
.braintree-hosted-fields-focused {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.braintree-hosted-fields-focused.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.checkbox-container {
  display: inline-block;
  float: top;
  margin-right: 8px;
}

.checkbox-container  input {
  margin-top: 0;
}


.checkbox-text-container {
  display: inline-block;
  width: 80%;
  vertical-align: top;
}

.checkbox-text-container p {
  color: #71737b;
  font-size: 14px;
  font-family: 'Circular','Arial',sans-serif !important;
}

.checkbox-text-container a {
  color: #71737b;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  font-family: 'Circular','Arial',sans-serif !important;
}

@media screen and (max-width: 768px) {
  .checkbox-text-container {
      display: inline-block;
      width: 90%;
      vertical-align: top;
  }
}

.checkbox-container {
  display: inline-block;
  float: top;
  margin-right: 8px;
}

.core-reg-steps-container .form-control {
  height: 48px;
  border-radius: 5px;
  /* border: 1px solid #d81f2a; */
  background-color: #f5f5f5;
  outline: none;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #474E5B;
  margin-top: 12px;
  margin-bottom: 36px;
}

.core-reg-steps-container  .form-group label {
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: #474E5B;
  display: block;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  top: 5px;
  left: 20px;
  pointer-events: none;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.core-reg-steps-container .form-group  input{
  width: 286px;
}

.error-message-signup {
  max-width: 290px;
}


.signin-container h1 {
  padding-bottom: 20px;
}

.desktop-yoga-icon {
  text-align: center;
}

.signin-button-container {
  margin-bottom: 24px;
}

.disclaimer {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 8px 0;
  padding: 0;
  width: 100%;
  text-align: left;
}

.disclaimer p {
  padding: 0 2%;
  width: 90%;
  color: #737373;
  font-weight: normal;
  font-size: 11px;
  letter-spacing: 0;
  margin: 2px 0;
}

#signin-form {
  max-width: 600px;
}

.form-control:focus {
  border-color: #FF5B14;
  box-shadow: 0 0 0 0.2rem rgba(255, 102, 2,.25);
}

.signin-button-container {
  text-align: center;
  margin-top: 8vh;
}

.signin-button {
  width: 266px;
  height: 60px;
  border-radius: 30px;
  background-color: #ff8a8a;
  border: none;
}

.running-button-container p {
  color: #182454 !important;
}

.signin-button:active {
  background-color: #eee;
  border: none;
}

.signin-button p {
  color: #fff;
  margin: 0.5rem 0;
  font-size: 19px;
  letter-spacing: 0.9px;
}

.signin-disclaimer p {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.signin-disclaimer a {
  text-decoration: underline;
}

.error-message {
  color: #be2202;
  font-size: 14px;
  text-align: center;
}

.underline-text {
  color: #be2202;
  text-decoration: underline;
}

.password-link {
  margin-top: 10px;
  text-align: center;
}

.password-link p {
  color: #80818B;
  cursor: pointer;
}

.password-link:hover {
  text-decoration: underline;
}

.divider-line {
  color: #CBCACE;
  width: 296px;
  margin: 23px 27px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em auto;
  padding: 0;
  justify-content: center;
}

.signup-link {
  margin-top: 10px;
  display: block;
  text-align: center;
}

.signup-link p {
  color: #F25E21;
  cursor: pointer;
}

.signup-link:hover {
  text-decoration: none;
}

.social-selection-button {
  height: 60px;
  width: 266px;
  border-radius: 30px;
  display: block;
  margin: 0 auto 20px;
}

.social-selection-button p {
  margin: 0 0 0 8px;
  text-align: center;
}

.social-selection-button img {
  margin: -5px 0 0 0;
}

.social-selection-button:hover {
  background-color:  #737373;
  border: none;
}

.social-selection-button:hover p {
  color: #fff;
}

.social-selection-button:focus {
  background-color: #222;
  border: none;
}

@media only screen and (min-width: 768px) {
  .disclaimer {
      bottom: 2vh;
      text-align: center;
  }
}

@media only screen and (min-width: 800px) {
  .card-content {
      max-width: 800px;
      padding: 8vh 0;
      margin-top: 2vh;
      border-radius: 25px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #eeeeee;
      background-color: #ffffff;
  }
  
  .disclaimer {
      position: inherit;
      margin-top: 4vh;
  }
}

@media only screen and (min-width: 1024px) {
  .disclaimer p {
      width: 70%;
  }
}


.cta-container {
  z-index: 10;
  text-align: center;
}

.yoga-cta-signedin-container {
  /* height: 278px;
  width: 256px; */
  z-index: 10;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 16px;
}

.cta-user-title h1 {
  font-weight: 400;
  color: #fff;
  font-size: 40px;
  padding: 0;
}

.cta-button-container {
  margin-top: 12px;
}

.cta-button-container Button {
  width: 314px;
  height: 60px;
  background-color: #ff8a8a;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.9px;
}

.yoga-button-container Button {
  background-color: #ff8a8a;
}

.running-button-container Button {
  background-color: #fff400;
  color: #182454;
}

.at_home-button-container Button {
  background-color: #FF5B14;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.at_home-button-container button {
  background-color: #FF5B14;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.at_home-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.cta-disclaimer {
  margin-top: 12px;
}

.cta-disclaimer p {
  font-size: 12px;
  font-weight: normal;
  color: #fff;
}

.cta-user-title {
  font-size: 46px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.curr-plan-disclaimer {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.cta-purchased-plan {
  margin-top: 16px;
  margin-bottom: 43px;
}

#purchased-plan-price {
  font-size: 48px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

#purchased-plan-periodicity {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}


@media only screen and (min-width: 992px) {
  .cta-container {
      width: 600px;
      padding-top: 32px;
  }

  .cta-container h1 {
      font-size: 38px;
  }

  .cta-button-container Button {
      width: 390px;
      font-size: 19px;
  }

  .cta-plan-price p {
      font-size: 16px;
  }

  .cta-disclaimer p {
      font-size: 14px;
  }
}

  
.cta-container-at-home button {
  height: auto;
  width: auto;
  padding: 30px 70px;
  border-radius: 60px;
  letter-spacing: 4px;
  display: flex;
  flex-wrap: nowrap;
  word-break: unset;
}

@media all and (max-width: 1023px) {

  .cta-container-at-home button {
      height: auto;
      width: auto;
      padding: 10px 25px;
      border-radius: 60px;
      letter-spacing: 3px;
      display: flex;
      flex-wrap: nowrap;
      word-break: unset;
      font-size: 16px;
      align-items: center;
      min-height: 60px;
  }
}
h2 {
  font-weight: bold !important; 
}
  
.sticky-cta-button {
  padding: 10px 20px;
}


.hero-container {
  margin-bottom: 0;
}

.hero-container video {
  object-fit: cover;
  position: absolute;
  top: 0;
  z-index: -10;
  height: 100vh;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(.2, .2, .2, 0.5);
}

.video-holder {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-holder>.row{width: 100vw;}

.hero-container h1 {
  color: #fff;
  font-weight: normal;
  font-size: 38px;
  padding: 0;
}

.hero-container p {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.partner-headline {
  margin: 72px 0 24px 0 !important;
}

.headline {
  text-align: center;
  margin-bottom: 48px;
}

.headline h1 {
  margin: 0;
}

.headline p {
  font-size: 9px;
  margin-top: -8px;
  letter-spacing: 1.2px;
}

.stars-rating {
  margin-top: 16px;
  height: 14px;
  width: 130px;
}

.sub-headlines {
  margin-bottom: 24px;
}

.sub-headlines h1 {
  font-size: 90px;
  text-align: center;
  font-weight: 300;
}

.hero-disclaimer p {
  font-size: 24px;
  max-width: 800px;
  font-weight: normal;
}

.hero-disclaimer {
  text-align: center;
  margin-bottom: 8px;
}

.hero-disclaimer img {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin: 0 8px 0 16px;
}

.hero-disclaimer p {
  font-size: 14px;
  font-weight: lighter;
  display: inline-block;
}

@media only screen and (max-width: 320px) {
  .hero-container {
      margin-top: -16px;
  }

  .headline img {
      height: 45px;
      width: 45px;
  }

  .hero-container h1 {
      font-size: 24px;
  }

  .hero-container p {
      font-size: 12px;
  }

  .sub-headlines {
      margin-bottom: 8px;
  }
}

@media only screen and (max-width: 360px) {
  .hero-container {
      margin-top: 0;
      margin-bottom: 0;
  }
}

@media only screen and (min-width: 992px) {
  .hero-disclaimer {
      margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .hero-container h1 {
      font-size: 90px;
  }

  .hero-container p {
      font-size: 24px;
  }

  .headline {
      margin-bottom: 32px;
  }

  .headline p {
      font-size: 19px;
      margin-top: -12px;
  }

  .hero-disclaimer img {
      width: 20px;
      height: 20px;
      margin: -4px 12px 0 16px;
  }

  .hero-icon {
      width: 104px;
      height: 104px;
  }

  .stars-rating {
      height: 14px;
      width: 130px;
  }
}

@media only screen and (min-width: 1024px) and (device-height: 1366px) {
  .hero-container video {
      height: 54vh;
  }

  .video-container {
      height: 54vh;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 1023px) {
  .sub-headlines h1 {
      font-size: 72px;
  }
  
  .hero-disclaimer p {
      font-size: 22px;
  }
}


@media only screen and (max-width: 767px) {
  .sub-headlines h1 {
      font-size: 60px;
  }
  
  .hero-disclaimer p {
      font-size: 18px;
  }
}

.social-banner-container-at-home {
  background: url("https://assets1.dailyburn.com/production/d2c/landing/running/social-banner-mobile.jpg") no-repeat center top;
  background-size: 100%;
  margin: 0 -15px;
  height: 693px;
}

.featured-in-container {
  margin-bottom: 60px;
}

.at-home-disclaimer-container {
  width: 98%;
  padding: 0 1%;
}

.at-home-disclaimer {
  font-weight: normal;
  font-size: 9px;
}

.social-headline h1 {
  font-size: 27px;
  line-height: 1.11;
  font-weight: normal;
  color: #fff;
  text-align: center;
}

.social-banner-headline {
  margin-top: 32px;
}

.social-banner-headline p {
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.88px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.social-at-home-icon {
  margin-top: 64px;
  text-align: center;
}

.social-buttons {
  text-align: center;
  margin-top: 16px;
}

.social-buttons img {
  margin: 6px;
}

@media only screen and (min-width: 768px) {
  .social-banner-container-at-home {
      margin: 0 -47px;
      background: url("https://assets1.dailyburn.com/production/d2c/landing/at-home/social-banner.jpg") no-repeat center top;

  }
}

@media only screen and (min-width: 992px) {
  .social-container-at-home {
      margin-right: 48px;
  }

  .social-headline h1 {
      font-size: 50px;
      font-weight: 300;
      padding-bottom: 0;
  }

  .social-at-home-icon {
      margin-top: 0;
  }

  .social-at-home-icon img {
      height: 120px;
      width: 322px;
  }

  .social-banner-content-at-home {
      padding-top: 60px;
  }

  .social-banner-app-store {
      margin-top: 120px;
  }
}

@media only screen and (min-width: 1200px) {
  .social-banner-container-at-home {
      margin: 0 -85px;
  }
}

@media only screen and (min-width: 1440px) {
  .social-banner-container-at-home {
      margin: 0 -165px;
  }
}

@media only screen and (min-width: 1920px) {
  .social-container-at-home {
      margin-right: 0;
  }

  .social-banner-container-at-home {
      margin: 0 -240px;
  }

  .at-home-disclaimer-container {
      margin-left: 180px;
  }
}

@media only screen and (min-width: 2000px) {
  .at-home-disclaimer-container {
      margin-left: 300px;
  }
}

@media only screen and (min-width: 2400px) {
  .at-home-disclaimer-container {
      margin-left: 500px;
  }
}

@media only screen and (min-width: 2800px) {
  .at-home-disclaimer-container {
      margin-left: 640px;
  }
}


.box-wrapper{
  margin: auto;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  padding: 40px;
  position: relative;
  flex-direction: column;
}

.box-holder{
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.box-holder-center {
  justify-content: center;
}

.box-holder h1{
  margin-bottom: 26px;
  text-align: center;
}

.box-content{
  width: 100%;
}

.check-your-em {
  font-size: 23px;
  font-weight: 400;
  color: #56606d;
  text-align: center;
}

.box-footer{
  text-align: center;
}

.box-footer .having-issues {
  font-size: 12px;
  line-height: 17px;
  color: #9b9b9b;
  text-align: center;
  margin: 0;
}

.having-issues span {
  color: #015a8f;
  cursor: pointer;
}

.box-wrapper .signin-button-container p{color: #fff;}

.signin-button-container .or-download-app {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #015a8f !important;
  margin-top: 21px;
  cursor: pointer;
  font-weight: 400;
}

@media only screen and (min-width: 999px) {
  .box-wrapper{
      padding: 56px;
      width: 805px;
      height: 636px;
      border-radius: 25px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #eeeeee;
      margin-top: 60px;
  }

  .box-content{
      width: 60%;
  }

  .check-your-em {
      margin-top: 90px;
  }

}

@media only screen and (max-width: 768px) {
  .box-holder {
      justify-content: center;
  }
}
