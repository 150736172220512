html * {
    font-family: 'Rubik', sans-serif !important;
    font-weight: 500;
    color: #58606c;
    font-display: swap;
}

body {
    background-color: #fcfcfc;
    overflow-x: hidden;
}

h1 {
    font-size: 20px;
    padding: 24px;
}

p {
    font-size: 14px;
}

[data-braintree-id="toggle"] {
    display: none;
}

.show-payment-method-toggle [data-braintree-id="toggle"] {
    display: block;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #fff;
    border-color: #71737B;
    border: 4px solid #71737b;
}
.btn-primary.disabled p, .btn-primary:disabled p {
    color: #71737B;
}
