.loading-container {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
}

.loading-container img {
    margin-top: 40vh;
}